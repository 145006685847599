<template>
    <section class="main-content-section">
        <div class="grid">
            <LoadingSpinner v-if="isLoading" />
            
            <div v-else class="col-8-12 push-2-12 sm-col-1-1 sm-push-0">
                <div class="form-container">
                    <div class="form-header">
                        <h1 class="page-title">{{personalInformation}}</h1>
                    </div>
                    
                    <form @submit.prevent="updateUserDetails()">
                        <div class="flex dual-inputs-container" :class="{'spacing': isFirstNameVisible && isLastNameVisible}">                       
                            <div v-if="isFirstNameVisible" class="form-row flex flex-column">
                                <label for="firstName">{{firstNameLabel}} <span v-if="isFirstNameRequired">*</span></label>
                                <template v-if="isFirstNameRequired">
                                    <input 
                                        type="text" 
                                        id="firstName"
                                        v-model.trim="firstName"
                                        @input="$v.firstName.$touch()"
                                        :class="{ error: $v.firstName.$error }" 
                                    />
                                    <div v-if="$v.firstName.$dirty">
                                        <p class="error" v-if="!$v.firstName.required">{{firstNameLabel}} {{isRequired}}</p>
                                        <p class="error" v-if="!$v.firstName.valid">{{invalidSpecialCharacters}}</p>
                                    </div>
                                </template>
                                <template v-else>
                                    <input 
                                        type="text" 
                                        id="firstName" 
                                        v-model.trim="firstName"
                                        @input="$v.firstName.$touch()"
                                        :class="{ error: $v.firstName.$error }" 
                                    />
                                    <div v-if="$v.firstName.$dirty">
                                        <p class="error" v-if="!$v.firstName.valid">{{invalidSpecialCharacters}}</p>
                                    </div>
                                </template>
                            </div>

                            <div v-if="isLastNameVisible" class="form-row flex flex-column">
                                <label for="lastName">{{lastNameLabel}} <span v-if="isLastNameRequired">*</span></label>
                                <template v-if="isLastNameRequired">
                                    <input 
                                        type="text" 
                                        id="lastName"
                                        v-model.trim="lastName"
                                        @input="$v.lastName.$touch()"
                                        :class="{ error: $v.lastName.$error }" 
                                    />
                                    <div v-if="$v.lastName.$dirty">
                                        <p class="error" v-if="!$v.lastName.required">{{lastNameLabel}} {{isRequired}}</p>
                                        <p class="error" v-if="!$v.lastName.valid">{{invalidSpecialCharacters}}</p>
                                    </div>
                                </template>
                                <template v-else>
                                    <input 
                                        type="text" 
                                        id="lastName" 
                                        v-model.trim="lastName"
                                        @input="$v.lastName.$touch()"
                                        :class="{ error: $v.lastName.$error }" 
                                    />
                                    <div v-if="$v.lastName.$dirty">
                                        <p class="error" v-if="!$v.lastName.valid">{{invalidSpecialCharacters}}</p>
                                    </div>
                                </template>
                            </div>
                        </div>

                        <div class="form-row flex flex-column">
                            <label for="email">{{emailAddress}} *</label>
                            <input 
                                id="email" 
                                type="email"
                                v-model.trim="email"
                                @input="$v.email.$touch()"
                                :class="{ error: $v.email.$error }" 
                            />
                            <div v-if="$v.email.$dirty">
                                <p class="error" v-if="!$v.email.email">{{validEmailAddress}}</p>
                                <p class="error" v-if="!$v.email.required">{{emailAddress}} {{isRequired}}</p>
                            </div>
                        </div>

                        <div class="form-row flex flex-column">
                            <label for="emailConfirmation">{{confirmEmailaddress}} *</label>
                            <input 
                                id="emailConfirmation" 
                                type="email"
                                v-model.trim="emailConfirmation"
                                @input="$v.emailConfirmation.$touch()"
                                :class="{ error: $v.emailConfirmation.$error }" 
                            />
                            <div v-if="$v.emailConfirmation.$dirty">
                                <p class="error" v-if="!$v.emailConfirmation.required">{{confirmEmailaddress}} {{isRequired}}</p>
                                <p class="error" v-if="!$v.emailConfirmation.sameAsEmail">{{incorrectEmailAddressConfirmation}}</p>
                            </div>
                        </div>

                        <div v-if="isLoyaltyCardVisible" class="form-row flex flex-column">
                            <label for="cardNumber">{{loyaltyCardNumber}} <span v-if="isLoyaltyCardRequired">*</span></label>
                            <template v-if="isLoyaltyCardRequired">
                                <input 
                                    type="text" 
                                    id="cardNumber" 
                                    v-model.trim="cardNumber"
                                    @input="$v.cardNumber.$touch()"
                                    :class="{ error: $v.cardNumber.$error }"
                                />
                                <div v-if="$v.cardNumber.$dirty">
                                    <p class="error" v-if="!$v.cardNumber.required">{{loyaltyCardNumber}} {{isRequired}}.</p>
                                    <p class="error" v-if="!$v.lastName.valid">{{invalidSpecialCharacters}}</p>
                                </div>
                            </template>
                            <template v-else>
                                <input 
                                    type="text" 
                                    id="cardNumber" 
                                    v-model.trim="cardNumber"
                                    @input="$v.cardNumber.$touch()"
                                    :class="{ error: $v.cardNumber.$error }" 
                                />
                                <div v-if="$v.cardNumber.$dirty">
                                    <p class="error" v-if="!$v.lastName.valid">{{invalidSpecialCharacters}}</p>
                                </div>
                            </template>
                        </div>

                        <div v-if="isPhoneVisible" class="form-row flex flex-column">
                            <label for="phone">{{phoneNumber}} <span v-if="isPhoneRequired">*</span></label>
                            <template v-if="isPhoneRequired">
                                <input 
                                    type="number" 
                                    id="phone" 
                                    v-model.trim="phone"
                                    @input="$v.phone.$touch()"
                                    :class="{ error: $v.phone.$error }"
                                />
                                <div v-if="$v.phone.$dirty">
                                    <p class="error" v-if="!$v.phone.required">{{phoneNumber}} {{isRequired}}</p>
                                    <p class="error" v-if="!$v.lastName.valid">{{invalidSpecialCharacters}}</p>
                                </div>
                            </template>
                            
                            <template v-else>
                                <input 
                                    type="number" 
                                    id="phone" 
                                    v-model.trim="phone"
                                    @input="$v.phone.$touch()"
                                    :class="{ error: $v.phone.$error }" 
                                />
                                <div v-if="$v.phone.$dirty">
                                    <p class="error" v-if="!$v.phone.valid">{{invalidSpecialCharacters}}</p>
                                </div>
                            </template>
                        </div>

                        <div v-if="isMobileVisible" class="form-row flex flex-column">
                            <label for="mobilePhone">{{mobileNumber}} <span v-if="isMobileRequired">*</span></label>
                            <template v-if="isMobileRequired">
                                <input 
                                    type="number" 
                                    id="mobilePhone" 
                                    v-model.trim="mobilePhone"
                                    @input="$v.mobilePhone.$touch()"
                                    :class="{ error: $v.mobilePhone.$error }"
                                />
                                <div v-if="$v.mobilePhone.$dirty">
                                    <p class="error" v-if="!$v.mobilePhone.required">{{mobileNumber}} {{isRequired}}</p>
                                    <p class="error" v-if="!$v.mobilePhone.valid">{{invalidSpecialCharacters}}</p>
                                </div>
                            </template>

                            <template v-else>
                                 <input 
                                    type="number" 
                                    id="mobilePhone" 
                                    v-model.trim="mobilePhone"
                                    @input="$v.mobilePhone.$touch()"
                                    :class="{ error: $v.mobilePhone.$error }" 
                                 />
                                 <div v-if="$v.mobilePhone.$dirty">
                                    <p class="error" v-if="!$v.mobilePhone.valid">{{invalidSpecialCharacters}}</p>
                                </div>
                            </template>
                        </div>

                        <div v-if="isDigitalCollector" class="form-row">
                            <input type="checkbox" id="collector" v-model="collector" @change="$v.collector.$touch()"
                            />
                            <label for="collector"><div class="custom-checkbox"></div>{{digitalCollector}}</label>
                        </div>

                        <div class="form-row">
                            <span class="small">* {{indicatesRequiredField}}</span>
                        </div>

                        <div class="form-row flex justify-center">
                            <button type="submit" class="btn btn-primary alternative submitBtn">{{updateAccountDetails}}</button>
                        </div>                      
                    </form>

                    <form @submit.prevent="submitNewPassword()">
                        <div class="form-row flex flex-column">
                            <label for="currentPassword">{{currentPasswordLabel}} *</label>
                            <input 
                                    id="currentPassword"
                                    type="password"
                                    autocomplete="off" 
                                    v-model.trim="currentPassword"
                                    @input="$v.currentPassword.$touch()"
                                    :class ="{ error: $v.currentPassword.$error }"
                                />
                                <div v-if="$v.currentPassword.$dirty">
                                    <p class="error" v-if="!$v.currentPassword.required">{{currentPasswordLabel}} {{isRequired}}.</p>
                                </div>
                        </div>

                        <div class="flex dual-inputs-container" :class="{'spacing': true}">
                            <div class="form-row flex flex-column">
                                <label for="newPassword">{{newPasswordLabel}} *</label>
                                <input 
                                    id="newPassword"
                                    type="password"
                                    autocomplete="off" 
                                    v-model.trim="newPassword"
                                    @input="$v.newPassword.$touch()"
                                    :class ="{ error: $v.newPassword.$error }"
                                />
                                <div v-if="$v.newPassword.$dirty">
                                    <p class="error" v-if="!$v.newPassword.required">{{newPasswordLabel}} {{isRequired}}</p>
                                    <p class="error" v-if="!$v.newPassword.minLength">{{passwordMinLength}}</p>
                                    <p class="error" v-if="!$v.newPassword.valid">{{passwordMustBeStronger}}</p>
                                </div>
                            </div>

                            <div class="form-row flex flex-column">
                                <label for="newPasswordConfirmation">{{confirmNewPasswordLabel}} *</label>
                                <input 
                                    id="newPasswordConfirmation"
                                    type="password"
                                    autocomplete="off" 
                                    v-model.trim="newPasswordConfirmation"
                                    @input="$v.newPasswordConfirmation.$touch()"
                                    :class ="{ error: $v.newPasswordConfirmation.$error }"
                                />
                                <div v-if="$v.newPasswordConfirmation.$dirty">
                                    <p class="error" v-if="!$v.newPasswordConfirmation.required">{{confirmNewPasswordLabel}} {{isRequired}}.</p>
                                    <p class="error" v-if="!$v.newPasswordConfirmation.sameAsNewPassword">{{incorrectPasswordConfirmation}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="form-row">
                            <span class="small">* {{indicatesRequiredField}}</span>
                        </div>

                        <div class="form-row flex justify-center">
                            <button class="btn btn-primary alternative submitBtn">{{updatePassword}}</button>
                        </div>                      
                    </form>

                    <div class="form-header">
                        <h1 class="page-title">{{deleteAccountLabel}}</h1>
                    </div>

                    <p class="info">{{toDeleteYourAccountClickTheButtonBelow}}</p>
                    
                    <div class="form-row flex justify-center">
                        <button @click="confirmDeleteAccount()" class="btn btn-secondary submitBtn">{{deleteMyAccount}}</button>
                    </div>
                </div>
            </div>
        </div>

        <WarningMessageModal 
            v-show="showWarningModal" 
            :title="errorTitle" 
            :text="errorMessage" 
            @closeModal="closeModal()" 
        />
        <ConfirmationModal 
            v-show="showDeleteAccountModal" 
            :title="areYouSure" 
            :text="thisActionCannotBeUndone" 
            @closeModal="showDeleteAccountModal = false" 
            @confirmed="deleteAccount()" 
        />
    </section>
</template>

<script>
import LoadingSpinner from '../../components/ui/LoadingSpinner.vue';
import agent from '../../api/agent';
import { required, email, sameAs, minLength } from 'vuelidate/lib/validators';
import WarningMessageModal from '@/components/modals/WarningMessageModal';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'MyAccount',
    components: { WarningMessageModal, ConfirmationModal, LoadingSpinner},

    data() {
        return {
            firstName: '',
            lastName: '',
            email: '',
            emailConfirmation: '',
            cardNumber: '',
            phone: '',
            mobilePhone: '',
            collector: false,
            currentPassword: '',
            newPassword: '',
            newPasswordConfirmation: '',
            isLoading: false,
            errorTitle: '',
            errorMessage: '',
            showWarningModal: false,
            showDeleteAccountModal: false,
            passwordUpdated: false,
        }
    },

    validations: {
        firstName: { 
            required,
             valid: (value) => {
                const containsSpecial = /[#?!@$%^&*-/";|<>+=\\(){}]/.test(value);
                return(
                    !containsSpecial
                );
             }
        },
        lastName: { 
             required,
             valid: (value) => {
                const containsSpecial = /[#?!@$%^&*-/";|<>+=\\(){}]/.test(value);
                return(
                    !containsSpecial
                );
            }
        },
        email: { required, email },
        emailConfirmation: { email, required, sameAsEmail: sameAs("email") },
        cardNumber: { 
            required,
            valid: (value) => {
                const containsSpecial = /[#?!@$%^&*-/";|<>+=\\(){}]/.test(value);
                return(
                    !containsSpecial
                );
            } 
        },
        phone: { 
            required,
            valid: (value) => {
                const containsSpecial = /[#?!@$%^&*-/";|<>+=\\(){}]/.test(value);
                return(
                    !containsSpecial
                );
            } 
        },
        mobilePhone: { 
            required,
            valid: (value) => {
                const containsSpecial = /[#?!@$%^&*-/";|<>+=\\(){}]/.test(value);
                return(
                    !containsSpecial
                );
            } 
        },
        collector: { required, sameAsChecked: sameAs(() => true) },
        newPassword: {
            required,
            minLength: minLength(8),
            valid: (value) => {
                const containsUppercase = /[A-Z]/.test(value);
                const containsLowercase = /[a-z]/.test(value);
                const containsNumber = /[0-9]/.test(value);
                const containsSpecial = /[#?!@$%^&*-]/.test(value);
                return (
                    containsUppercase &&
                    containsLowercase &&
                    containsNumber &&
                    containsSpecial
                ); 
            }
        },
        currentPassword: { required },
        newPasswordConfirmation: { required, sameAsNewPassword: sameAs("newPassword") },
    },

    async created() {
        this.setDocumentTitle();
        // const result = await agent.users.userDetails(this.userId);
        // // await this.$store.dispatch('fetchUserDetails', this.userId);

        // this.firstName = result.firstName;
        // this.lastName = result.lastName;
        // this.email = result.email;
        // this.phone = result.phone;
        // this.mobilePhone = result.mobile;
        // this.collector = result.isDigitalSaver;
        try {
            const result = await agent.users.userDetails(this.userId);
            
            this.firstName = result.firstName;
            this.lastName = result.lastName;
            this.email = result.email;
            this.phone = result.phone;
            this.mobilePhone = result.mobile;
            this.collector = result.isDigitalSaver;
        } catch (error) {
            console.error(error);
        }
    },

    updated() {
       this.setDocumentTitle(); 
    },

    computed: {
        ...mapGetters([
            'metaTitle',
            // Settings
            'isFirstNameVisible',
            'isFirstNameRequired',
            'isLastNameVisible',
            'isLastNameRequired',
            'isLoyaltyCardVisible',
            'isLoyaltyCardRequired',
            'isPhoneVisible',
            'isPhoneRequired',
            'isMobileVisible',
            'isMobileRequired',
            'isDigitalCollector',
            'recaptchaValidation',          
            // Texts
            'personalInformation',
            'isRequired',
            'passwordMinLength',
            'indicatesRequiredField',
            'notAllRequiredFieldsAreCompleted',
            'emailAddress',
            'confirmEmailaddress',
            'validEmailAddress',
            'incorrectEmailAddressConfirmation',
            'loyaltyCardNumber',
            'phoneNumber',
            'mobileNumber',
            'digitalCollector',
            'updateAccountDetails',
            'updatePassword',
            'passwordMustBeStronger',
            'incorrectPasswordConfirmation',
            'toDeleteYourAccountClickTheButtonBelow',
            'deleteMyAccount',
            'areYouSure',
            'thankYou',
            'thisActionCannotBeUndone',
            'yourPasswordHasBeenUpdated',
            'yourPersonalDataHasBeenUpdated',
            'myAccount',
            'invalidSpecialCharacters'
        ]),
        ...mapGetters({
            userId: 'getUserId',
            userDetails: 'getUserData',

            // Texts
            firstNameLabel: 'firstName',
            lastNameLabel: 'lastName',
            currentPasswordLabel: 'currentPassword',
            newPasswordLabel: 'newPassword',
            confirmNewPasswordLabel: 'confirmNewPassword',
            deleteAccountLabel: 'deleteAccount'

        })
    },

    methods: {
        setDocumentTitle() {
            document.title = this.myAccount + this.metaTitle;
        },
        
        showModal() {
            this.showWarningModal = true;
        },

        closeModal() {
            this.showWarningModal = false;
            this.$store.commit('CLEAR_ERRORS');
        },

        async submitUserDetails() {
            this.isLoading = true;
            try {
                await this.$store.dispatch('updateUserDetails', {
                    userId: this.userId,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    phone: this.phone,
                    mobile: this.mobilePhone,
                    isDigitalSaver: this.collector
                });
                this.isLoading = false;
                if (this.$store.state.showErrorWarning) {
                    this.errorTitle = this.$store.state.errorTitle;
                    this.errorMessage = this.$store.state.errorMessage;
                    this.showModal();
                    } else {
                    this.errorTitle = this.thankYou;
                    this.errorMessage = this.yourPersonalDataHasBeenUpdated;
                    this.showModal();
                }
            } catch (error) {
                this.isLoading = false;
            }
        },

        async submitUpdatedPassword() {
            this.isLoading = true;
            try {
                await this.$store.dispatch('changePassword', {
                    userId: this.userId,
                    currentPassword: this.currentPassword,
                    changedPassword: this.newPassword
                });                
                this.isLoading = false;
                if (this.$store.state.showErrorWarning) {
                    this.errorTitle = this.$store.state.errorTitle;
                    this.errorMessage = this.$store.state.errorMessage;
                    this.showModal();
                } else {
                    this.errorTitle = this.thankYou;
                    this.errorMessage = this.yourPasswordHasBeenUpdated;
                    this.showModal();
                }
            } catch (error) {
                this.isLoading = false;
            }
        },

        saveNewPasswordToStore() {
            this.$store.dispatch('setPassword', this.password);
            this.$store.dispatch('setPasswordConfirmation', this.passwordConfirmation);
        },

        async validateRecaptcha(method, token) {
            await this.$store.dispatch('validateRecaptcha', token);
            this.loading = false;
            if (this.recaptchaValidation === true) {
                if (method === 'submitUserDetails') {
                    this.submitUserDetails();
                    }
                if (method === 'submitUpdatedPassword') {
                    this.submitUpdatedPassword();
                }
            } else {
                this.errorTitle = 'Something went wrong';
                this.errorMessage = 'Please try again';
                this.showModal();
            }
        },

        async getRecaptchaToken(method) {
            this.isLoading = true;
            await this.$recaptchaLoaded();
            const token = await this.$recaptcha('login');
            this.validateRecaptcha(method, token);
        },

        updateUserDetails() {
            // Warning modal message handlers and prevent navigation to next step 
            if (this.isFirstNameVisible && this.isFirstNameRequired && this.$v.firstName.$invalid) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage = this.firstNameLabel + ' ' + this.isRequired;
                this.showModal();
                return;
            }

            if (this.isLastNameVisible && this.isLastNameRequired && this.$v.lastName.$invalid) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage = this.lastNameLabel + ' ' + this.isRequired;
                this.showModal();
                return;
            }

            if (this.$v.email.$invalid) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage = this.emailAddress + ' ' + this.isRequired;
                this.showModal();
                return;
            }

            if (this.$v.emailConfirmation.$invalid) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage = this.confirmEmailaddress + ' ' + this.isRequired;
                this.showModal();
                return;
            }

            if (this.isLoyaltyCardVisible && this.isLoyaltyCardRequired && this.$v.cardNumber.$invalid) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage = this.loyaltyCardNumber + ' ' + this.isRequired;
                this.showModal();
                return;
            }

            if (this.isPhoneVisible && this.isPhoneRequired && this.$v.phone.$invalid) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage = this.phoneNumber + ' ' + this.isRequired;
                this.showModal();
                return;
            }

            if (this.isMobileVisible && this.isMobileRequired && this.$v.mobilePhone.$invalid) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage = this.mobileNumber + ' ' + this.isRequired;
                this.showModal();
                return;
            }

            if (this.isDigitalCollector && this.$v.collector.$invalid) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage = this.digitalCollector + ' ' + this.isRequired;
                this.showModal();
                return;
            }

            this.getRecaptchaToken('submitUserDetails');
        },

        submitNewPassword() {
            if (this.$v.currentPassword.$invalid) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage = this.currentPasswordLabel + ' ' + this.isRequired;
                this.showModal();
                return;
            }

            if (this.$v.newPassword.$invalid) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage = this.newPasswordLabel + ' ' + this.isRequired;
                this.showModal();
                return;
            }

            if (this.$v.newPasswordConfirmation.$invalid) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage = this.confirmNewPasswordLabel + ' ' + this.isRequired;
                this.showModal();
                return;
            }

            this.getRecaptchaToken('submitUpdatedPassword');
        },

        deleteAccount() {
            this.$store.dispatch('removeUser', this.userId);
        },

        confirmDeleteAccount() {
            this.showDeleteAccountModal = true;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/global-styles/variables';

.form-header {
 border-bottom: 1px solid $grey-light-2;
}

.page-title {
    text-align: left;
    margin-bottom: 2rem;
}

.info {
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 1.8rem;
}

form {
    margin-top: 3rem;
}

.dual-inputs-container {
    &.spacing{
        div:first-child {
            margin-right: 2rem;
        }
    }

    div {
        flex: 1;
    }
}

.form-row {
    margin-bottom: 3rem;
}

a,
a.btn {
    font-size: inherit;
}

a.btn {
    text-decoration: none;

    &.btn-secondary:hover {
        color: $white;
    }
}

.submitBtn {
    width: 25rem;
    text-align: center;
}

@media only screen and (max-width: 530px) {
    .dual-inputs-container {
        flex-direction: column;

        div:first-child {
            margin-right: 0;
        }
    }

    .btn {
        font-size: 1.4rem;
    }
}
</style>